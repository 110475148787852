<template>
  <el-card id="massNoteAudit">
    <header>
      <h1>群发短信内容审核</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>公司ID</span>
            <el-input
              v-model="parent_id"
              placeholder="请输入公司ID"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>企业名称</span>
            <el-input
              v-model="p_realname"
              placeholder="请输入企业名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>运营人员</span>
            <el-input
              v-model="operators"
              placeholder="请输入运营人员"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>群发短信状态</span>
            <el-select
              v-model="mass_status"
              placeholder="请选择状态"
              size="small"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span style="width: 11%">群发时间</span>
            <el-date-picker
              v-model="mass_time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="p_realname"
          label="企业名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="u_realname"
          label="客户姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="operators"
          label="运营人员"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mass_time"
          label="群发审核时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.mass_time) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mass_status" label="群发审核状态" align="center">
          <template v-slot="scope">
            <div :class="fontClass(scope.row.mass_status)">
              {{ statusClass(scope.row.mass_status) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="140">
          <template v-slot="scope">
            <el-button
              v-show="distinguishStatus(scope.row.mass_status)"
              type="primary"
              plain
              disabled
              size="small"
              >审核</el-button
            >
            <el-button
              type="primary"
              v-show="!distinguishStatus(scope.row.mass_status)"
              @click="setAudit(scope)"
              style="margin-left: 0"
              size="small"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 审核弹出框 -->
    <el-dialog title="审核" :visible.sync="setAuditVisible" :width="'30%'">
      <el-form :model="forms" class="audit" label-width="100px">
        <el-form-item label="企业名称" prop="user_realname">
          <el-input
            v-model="forms.p_realname"
            placeholder="请输入企业名称"
            style="width: 220px"
            disabled="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名" prop="active_phone">
          <el-input
            v-model="forms.u_realname"
            placeholder="请输入客户姓名"
            style="width: 220px"
            disabled="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="运营人员" prop="company">
          <el-input
            v-model="forms.operators"
            placeholder="请输入运营人员"
            style="width: 220px"
            disabled="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="模板内容" prop="company">
          <el-input
            v-model="forms.mass_content"
            placeholder="请输入模板内容"
            disabled="false"
            type="textarea"
            :rows="8"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="rejectSubmit" type="danger">驳 回</el-button>
        <el-button @click="passSubmit(1)" type="success">通 过</el-button>
        <el-button @click="setAuditVisible = false" type="primary"
          >退 出</el-button
        >
      </template>
    </el-dialog>
    <!-- 驳回弹出框 -->
    <el-dialog title="驳回原因" :visible.sync="rejectVisible" :width="'40%'">
      <el-form :model="forms">
        <el-form-item label="驳回原因" prop="massRemark">
          <el-input
            type="textarea"
            :rows="8"
            v-model="massRemark"
            placeholder="请输入驳回原因"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer
        ><el-button @click="rejectVisible = false" type="primary"
          >取 消</el-button
        >
        <el-button @click="passSubmit(2)" type="danger">驳 回</el-button>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { smsTemplateUpdate, smsTemplateList } from "@/api/sms-template";
export default {
  data() {
    return {
      forms: {
        p_realname: null,
        u_realname: null,
        operators: null,
        mass_content: null,
      },
      massRemark: null,
      rejectVisible: false,
      setAuditVisible: false,
      parent_id: null,
      p_realname: null,
      operators: null,
      mass_status: null,
      mass_time: null,
      options: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核未通过",
        },
      ],
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.user_realname = localStorage.getItem("user_realname");
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.value = localStorage.getItem("status") || "";
    this.getList();
  },
  watch: {
    value() {
      this.getList();
    },
  },
  methods: {
    // 按钮状态
    distinguishStatus(mass_status) {
      if (mass_status == 2) {
        return true;
      }
    },
    // 状态分类
    statusClass(mass_status) {
      // console.log("状态的值", mass_status);
      if (mass_status == 0) {
        return "待审核";
      } else if (mass_status == 1) {
        return "审核通过";
      } else if (mass_status == 2) {
        return "审核未通过";
      }
    },

    // 状态分类字体样式
    fontClass(mass_status) {
      // console.log("状态的值", mass_status);
      if (mass_status == 0) {
        return "mass_status0";
      } else if (mass_status == 1) {
        return "mass_status1";
      } else if (mass_status == 2) {
        return "mass_status2";
      }
    },

    // 驳回弹出弹出框
    rejectSubmit() {
      this.rejectVisible = true;
    },
    // 审核
    setAudit(scope) {
      // console.log('审核',scope);
      this.forms = scope.row;
      this.name = scope.row.p_realname;
      this.u_realname = scope.row.u_realname;
      this.operators = scope.row.operators;
      this.mass_content = scope.row.mass_content;
      this.id = scope.row.sid;
      this.setAuditVisible = true;
    },
    // 确定审核
    passSubmit(massStatus) {
      let params = {
        id: this.id,
        massStatus,
        massContent: this.mass_content,
      };
      if (massStatus == 2) {
        params.massRemark = this.massRemark;
      }
      let res = smsTemplateUpdate(params);
      res.then((res) => {
        this.rejectVisible = false;
        Message.success(res.data.message);
        this.setAuditVisible = false;
        this.getList();
      });
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },

    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //重置刷新按钮
    reset() {
      this.parent_id = null;
      this.p_realname = null;
      this.u_realname = null;
      this.operators = null;
      this.mass_status = null;
      this.mass_time = null;
      this.getList();
    },

    //点击查找
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        name: this.p_realname,
        operators: this.operators,
        massStatus: this.mass_status,
        hangupStatus: this.hangup_status,
        parentId: this.parent_id,
      };
      if (this.mass_time) {
        params.massSta = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.mass_time[0]
        );
        params.massEnd = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.mass_time[1]
        );
      }
      let res = smsTemplateList(params);
      res.then((res) => {
        this.pageTotal = res.data.total;
        this.tableData = res.data.data;
        this.tableData.forEach((v) => {
          v.mass_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", v.mass_time);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
#massNoteAudit {
  .mass_status0 {
    color: #e6a23c;
  }
  .mass_status1 {
    color: #67c23a;
  }
  .mass_status2 {
    color: #f56c6c;
  }
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    // height: 58vh;
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>