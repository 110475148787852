/**
 * sms-template
 * 短信模板
 */

import request from '@/utils/request'

// 编辑
export const smsTemplateUpdate = (data) => {
    return request({
        method: 'post',
        url: '/sms-template/update',
        data
    })
}
// 获取
export const smsTemplateList = (data) => {
    return request({
        method: 'post',
        url: '/sms-template/list',
        data
    })
}
// 查询
export const smsTemplateFindOne = (data) => {
    return request({
        method: 'post',
        url: '/sms-template/findOne',
        data
    })
}
// 添加
export const smsTemplateAdd = (data) => {
    return request({
        method: 'post',
        url: '/sms-template/add',
        data
    })
}
